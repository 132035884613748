export const SCREEN_SM = 480;
export const SCREEN_MD = 768;
export const LOADED_KEY = 'loaded';
export const SAVED_KEY = 'saved';
export const SUCCESS_PATCH_MESSAGE = 'Изменения внесены'
export const REGEX_NAME = /^[а-яА-Яa-zA-ZЁёәіңғүұқөһӘІҢҒҮҰҚӨҺ\-\s]*$/;
export const SHORT_FILM_DURATION = 40;
export const NUMBER_CARDS_SCREEN_LG = 8
export const NUMBER_CARDS_SCREEN_MD = 8
export const NUMBER_CARDS_SCREEN_SM = 5
export const QUANTITY_TO_ADDED_SCREEN_LG = 4
export const QUANTITY_TO_ADDED_SCREEN_MD = 2
export const QUANTITY_TO_ADDED_SCREEN_SM = 2
